<!-- eslint-disable vue/multi-word-component-names -->
<!-- header.vue -->
<template>
    <!-- header -->
    <header id="header" :class="{ 'fix': isHeaderFixed, 'fix2': isHeaderFixed2 }">
        <div class="head_box">
            <h1 class="logo">
                <router-link to="/"><img src="../../assets/img/logo.svg" alt="MAGICBODY"></router-link>
            </h1>
            
            <!-- 상단메뉴 -->
            <nav id="gnb">
                <ul class="gnb">
                    <li v-for="(item, i) in menuItems" :key="i">
                        <router-link :to="'/classList/'+item.categoryid+'/'+item.pcategoryid" @click="changeGNB(item.categoryid, item.pcategoryid);">{{ item.menuName }}</router-link>
                    </li>
                </ul>
            </nav>
            <!-- //상단메뉴 -->

            <!-- 로그아웃 상태 -->
            <div class="glob_con" v-if="!isUserLoggedIn">
                <button class="btn_search" @click="toggleSearch">검색</button>
                <!-- <span class="btn_join"><router-link to="/join">회원가입</router-link> </span> -->
                <span class="btn_login"><router-link to="/login">로그인</router-link> </span>
                <button class="btn_total" @click="toggleMenu" :class="{ 'on': isMenuOpen }"><span>전체메뉴</span></button>
            </div>
            <!-- //로그아웃 상태 -->

            <!-- 로그인 상태 -->
            <div class="glob_con" v-else>
                <span class="btn_my_lect"><router-link to="/myclass">내강의실</router-link> </span>
                <button class="btn_search" @click="toggleSearch">검색</button>
                <span class="btn_basket " :class="{ 'alim': cartCnt !== 0 }"><router-link to="/mycart"></router-link> </span>
                <button class="btn_total" @click="toggleMenu" :class="{ 'on': isMenuOpen }"><span>전체메뉴</span></button>
            </div>
            <!-- //로그인 상태 -->

            <!-- 상단 / 모바일 검색 -->
            <MainSearch ref="mainSearch" @toggleEvent="toggleSearch"/>
            <!-- //상단 / 모바일 검색 -->

            <!-- 전체메뉴 및 모바일메뉴 -->
            <div id="totalMb" :class="{ 'on': isMenuOpen }">
                <div class="total_box">
                    <div class="menu_box">
                        <nav class="totlal_menu">
                            <ul>
                                <li v-for="(item, i) in menuItems" :key="i">
                                    <router-link :to="'/classList/'+item.categoryid+'/'+item.pcategoryid"
                                        @click="changeGNB(item.categoryid, item.pcategoryid);">{{ item.menuName}}</router-link>
                                    <ul v-if="item.hasChildren">
                                        <li v-for="(subMenu, j) in item.subMenu" :key="j">
                                            <router-link :to="'/classList/'+subMenu.categoryid+'/'+subMenu.pcategoryid"
                                                @click="changeGNB(subMenu.categoryid, subMenu.pcategoryid);">{{ subMenu.menuName }}</router-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>

                        <!-- 로그인 상태  -->
                        <div class="my_infor" v-if="isUserLoggedIn">
                            <div class="name"><b>{{ username }}</b> 님</div>
                            <span class="btn_logout"><a href="javascript:;" @click="logout();">로그아웃</a></span>
                            <ul>
                                <li>
                                    <span @click="movePage('mycart');" class="num cursorPointer">{{ cartCnt }}</span> 건
                                    <span @click="movePage('mycart');" class="txt cursorPointer">장바구니</span>
                                </li>
                                <li>
                                    <span @click="movePage('myclass');" class="num cursorPointer">{{ classCnt }}</span> 건
                                    <span @click="movePage('myclass');" class="txt cursorPointer">수강중인클래스</span>
                                </li>
                                <li>
                                    <span @click="movePage('mypoint');" class="num cursorPointer">{{ pointSum }}</span> P
                                    <span @click="movePage('mypoint');" class="txt cursorPointer">포인트</span>
                                </li>
                            </ul>
                        </div>
                        <dl class="my_menu" v-if="isUserLoggedIn">
                            <dt>내강의실</dt>
                            <dd>
                                <ul>
                                    <li><router-link to="/myclass">수강중인 클래스</router-link></li>
                                    <li><router-link to="/myclassEnd">종료된 클래스</router-link></li>
                                    <li><router-link to="/myclass">패키지 조회</router-link></li>
                                    <li><router-link to="/myEbook">전자책 관리</router-link></li>
                                    <li><router-link to="/myBookmark">북마크</router-link></li>
                                    <li><router-link to="/myCertificates">수료증 발급</router-link></li>
                                    <li><router-link to="/mypayment">결제내역 조회</router-link></li>
                                    <li><router-link to="/myprofile">회원정보 수정</router-link></li>
                                </ul>
                            </dd>
                        </dl>
                        <!-- //로그인 상태 -->

                        <!-- 로그아웃 상태 -->
                        <div class="my_login" v-else>
                            <h2>로그인</h2>
                            <div class="login_con">
                                <span class="btn_login_kaka" @click.prevent="signInWithKakao()"><a href="javascript:;">카카오로 3초 만에 시작하기</a></span>
                                <div id="naverIdLogin">
                                    <a id="naverIdLogin_loginButton" href="javascript:;">
                                        <span class="btn_login_nave" @click="handleNaverLogin();">
                                            <a href="javascript:;">네이버 아이디로 로그인</a>
                                        </span>
                                    </a>
                                </div>
                                <div class="login_ch">
                                    <div class="lt"><input type="checkbox" id="auto" class="check_box"><label for="auto">자동
                                            로그인</label></div>
                                    <!-- <div class="rt">
                                        <span>아직 회원이 아니라면?</span> <router-link to="/join">회원가입</router-link>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <!-- //로그아웃 상태 -->
                    </div>
                    <div class="menu_bottom">
                        <ul class="foot_menu">
                            <li><router-link to="/company">회사소개</router-link></li>
                            <li><router-link to="/privacyPolicy">개인정보 처리방침</router-link></li>
                            <li><router-link to="/termsOfUse">이용약관</router-link></li>
                            <li><router-link to="/q&a">FAQ</router-link></li>
                            <li><router-link to="/notice">공지사항</router-link></li>
                        </ul>
                        <ul class="foot_sns">
                            <li class="ic1"><a target="_blank" href="https://www.instagram.com/magicbodypilates">인스타그램</a> </li>
                            <li class="ic3"><a target="_blank" href="https://www.youtube.com/channel/UCRGV8nPbhjGsSIsPl5ypImQ">유튜브</a> </li>
                            <li class="ic4"><a target="_blank" href="https://pf.kakao.com/_UPxnqxl">카카오톡</a> </li>
                            <li class="ic5"><a target="_blank" href="https://cafe.naver.com/magicbody">블로그</a> </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- //전체메뉴 및 모바일메뉴 -->
            <div class="fix_bg"></div>
        </div>
    </header>
</template>


<script>
import CommonEvents from '../../assets/js/common';
import MainSearch from '../layout/mainSearch.vue';
import { menuItems } from "@/components/layout/menu";
import { supabase } from '../../lib/supabaseClient'
import { authLogin, user, authLogout } from '../../composables/useAuth';
import VueCookies from 'vue-cookies';

export default {
    mixins: [CommonEvents], // 공통js
    data() {
        return {
            schtext: '',
            isMenuOpen: false,
            isUserLoggedIn: false,
            isHeaderFixed: false,
            isHeaderFixed2: false,
            menuItems: menuItems,
            ids: {
                categoryid: "",
                pcategoryid: "",
            },
            useruid: "",
            username: "",
            cartCnt: 0,
            classCnt: 0,
            expireCnt: 0,
            pointSum: 0,
            routeName: "",
        };
    },
    components: {
        MainSearch
    },
    watch: {
        '$route'() { //링크이동시 전체메뉴 닫기
            this.isMenuOpen = false;
            document.body.style.overflow = this.isMenuOpen ? 'hidden' : 'auto';
        }
    },
    async created(){

        this.routeName = this.$router.currentRoute.value.name;

        // 라우터 변경 감지를 위한 $watch 설정
        this.$watch(() => this.$route, (to, from) => {
            // 현재 라우트 이름 업데이트
            this.routeName = to.name;
            if(to.name != "myprofile"){
                this.checkUserPhone();
            }
        });

        
        // 임시 고정 로그인
       /*  var userInfo = {
            "accessToken": "111",
            "refreshToken": "222",
            "email": "juheek0220@naver.com",
            "useruid": "bc9c0232-e500-45ef-ad01-3c91ace1e3e0",
            "username": "김주희",
            "sessionToken": "2829a1e3-5747-4cb1-b458-3a935b766a71",
        };

        this.$store.commit('setLoggedIn', true);
        this.$store.commit('setUser', userInfo); */
       

        // 인증 상태 변경 이벤트를 감지하여 처리합니다.
        supabase.auth.onAuthStateChange((event, session) => {
            // 사용자가 로그인한 경우
            if(!this.routeName.includes("paymentResult") && !this.routeName.includes("paymentSuccess") && !this.routeName.includes("paymentFail")){
                //console.log(event);
                //console.log(session);
                if (event === 'SIGNED_IN') {
                    //console.log('사용자가 로그인했습니다.');
                    //console.log(session);
                    
                    this.checkUserAccount(session.user.user_metadata, session.access_token, session.refresh_token, session.user.id);
                }
            }
        });

    },
    mounted() {

        var user = this.$store.getters['user'];
        if(user.loggedIn){
            this.isUserLoggedIn = true;
            this.useruid = user.data.useruid;
            this.username = user.data.username;

            this.getMyClassInfo();
            this.checkUserPhone();
        }else{
            this.isUserLoggedIn = false;
            this.useruid = "";
        }

        this.handleSupabaseResponse();

        this.emitter.on('watchLoginChange', this.onWatchLoginChange);
        this.emitter.on('watchCartChange', this.getMyClassInfo);
    },
    beforeUnmount(){
        this.emitter.off('watchLoginChange', this.onWatchLoginChange);
        this.emitter.off('watchCartChange', this.getMyClassInfo);
    },
    methods: {

        // 전화번호 저장 여부 확인
        checkUserPhone(){
            var user = this.$store.getters['user'];
            if(user.loggedIn){
                var params = {
                    useruid: user.data.useruid
                };
                this.$store.dispatch('userStore/checkUserPhone', params)
                .then((res) => {
                    //console.log(res);
                    if(res.success){
                        if(!res.datas.exists){
                            this.alertMessage(res.respMessage);
                            this.$router.push('/myprofile');
                        }
                    }
                    return res;
                })
                .catch(({message}) => (this.alertMessage(message)));
            }
        },

        // 로그인 상태 변경 감지
        onWatchLoginChange(){
            var user = this.$store.getters['user'];
            if(user.loggedIn){
                this.isUserLoggedIn = true;
                this.useruid = user.data.useruid;
                this.username = user.data.username;

                this.getMyClassInfo();
            }else{
                this.isUserLoggedIn = false;
                this.useruid = "";
            }
        },

        // 사용자 계정 체크
        checkUserAccount(session, accessToken, refreshToken, useruid) {
            //console.log("사용자 계정 체크");
            //console.log(session);

            var email = session.email;
            var username = session.full_name;

            var params = {
                email: email,
            };

            this.$store.dispatch('userStore/checkUserAccount', params)
            .then((res) => {
                //console.log("checkUserAccount");
                //console.log(res);

                if(res.success) {
                    // 기존 사용자일 경우

                    var userInfo = {
                        "accessToken": accessToken,
                        "refreshToken": refreshToken,
                        "email": email,
                        "useruid": res.datas.useruid,
                        "username": username,
                    };
                    this.setUserInfo(userInfo);

                }else{
                    // 처음 로그인 -> 회원가입 진행

                    var params = {
                        "useruid": useruid,
                        "userid": email,
                        "email": email,
                        "name": username,
                    };

                    this.$store.dispatch('userStore/createSNSUser', params)
                    .then((res) => {
                        //console.log(res);

                        if(res.success) {

                            var userInfo = {
                                "accessToken": accessToken,
                                "refreshToken": refreshToken,
                                "email": email,
                                "useruid": useruid,
                                "username": username,
                            };
                            this.setUserInfo(userInfo);

                        }else{
                            //this.alertMessage(res.respMessage);
                            this.alertMessage(res.respMessage);

                        }
                        return res;
                    })
                    .catch(({message}) => (this.alertMessage(message)));
                }
                return res;
            })
            .catch(({message}) => (this.alertMessage(message)));

        },

        // 사용자 정보 셋팅
        async setUserInfo(userInfo){
            var magicbody_sessionToken = VueCookies.get('magicbody_sessionToken');
            if(magicbody_sessionToken){
                userInfo.sessionToken = magicbody_sessionToken;
            }else{
                try {
                    var user = await authLogin(userInfo.useruid, userInfo.email);
                    //console.log(user);
                    userInfo.sessionToken = user.sessionToken;
                } catch (error) {
                    console.error('Login failed:', error);
                }
            }
            
            this.$store.commit('setLoggedIn', true);
            this.$store.commit('setUser', userInfo);

            this.onWatchLoginChange();

            this.emitter.emit('watchLoginChange_main');
        },

        // 내 강의실 정보 가져오기
        getMyClassInfo(){

            var params = {
                useruid: this.useruid
            };
                    
            this.$store.dispatch('mypageStore/getMyClassInfo', params)
            .then((res) => {
                //console.log(res);
                if(res.success){

                    var myclassInfo = res.datas;
                    this.cartCnt = myclassInfo.cartCnt;
                    this.classCnt = myclassInfo.classCnt;
                    this.expireCnt = myclassInfo.expireCnt;
                    this.pointSum = this.addCommasToNumber(myclassInfo.pointSum);
                    
                }
                return res;
            })
            .catch(({message}) => (this.alertMessage(message)));
        },

        // 숫자 3자리마다 콤마 추가
        addCommasToNumber(number) {
            if(number && number > 0){
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }else{
                return "0";
            }
        },

        toggleMenu() {  //상단 메뉴
            this.isMenuOpen = !this.isMenuOpen;
            // Sidebar가 나타날 때 body에 overflow: hidden , auto 토글
            document.body.style.overflow = this.isMenuOpen ? 'hidden' : 'auto';        
        },
        
        toggleSearch() {
            this.$refs.mainSearch.toggleSearch(); // MainSearch 컴포넌트의 toggleSearch() 메서드 호출
        },

        // 온라인강의 메뉴 클릭 시 emitter 발동
        changeGNB(categoryid, pcategoryid){
            this.ids.categoryid = categoryid;
            this.ids.pcategoryid = pcategoryid;
            this.emitter.emit('changeGNB', this.ids);
        },

        // 로그아웃
        async logout(){
            
            const { error } = await supabase.auth.signOut();
            if (error) {
                console.error('로그아웃 에러:', error.message);
            } else {
                //console.log('로그아웃 성공');
                // 로그아웃 후에 실행할 작업 수행
                this.handlePostLogoutActions();
            }
        },

        // 로그아웃 후 실행
        handlePostLogoutActions(){
            this.$store.commit('logoutUser');
            this.$router.push('/login');
            this.isUserLoggedIn = false;
        },

        // 카카오 로그인
        async signInWithKakao() {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'kakao',
            });

            //console.log("카카오 로그인");
            //console.log(data);

            if (error) {
                console.error('카카오 로그인 에러:', error.message);
            } else {
                //console.log('카카오 로그인 성공:', data);
                // 로그인 후에 실행할 작업 수행
                this.handlePostLoginActions();
            }
        },

        handlePostLoginActions() {
            // 로그인 후에 실행할 작업을 정의합니다.
            //console.log('로그인 후에 실행할 작업 수행');
            // 이 부분에 원하는 작업을 추가합니다.
        },

        // 네이버 로그인
        handleNaverLogin (){
            const clientId = 'lBrmRQ3S0xY1_GR64p6O';
            const redirectUri = 'https://bvrobftyqlzygmoehqsc.supabase.co/functions/v1/naver-oauth';
            const state = 'some-random-state'; // CSRF 공격을 방지하기 위해 사용
            const authUrl = `https://nid.naver.com/oauth2.0/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&state=${state}`;

            window.location.href = authUrl;
            
        },

        // 로그인 후 처리
        async handleSupabaseResponse() {
            const userInfo = this.$route.query;
            
            if(userInfo){
                if(userInfo.success != undefined){
                    if(userInfo.success == "true"){

                        try {
                            var user = await authLogin(userInfo.useruid, userInfo.email);
                            //console.log(user);
                            if(user.success){
                                userInfo.sessionToken = user.sessionToken;

                                this.$store.commit('setLoggedIn', true);
                                this.$store.commit('setUser', userInfo);

                                this.emitter.emit('watchLoginChange');

                                this.$router.push('/main');
                            }else{
                                //this.alertMessage(user.error);
                                this.alertMessage(user.error);
                                return false;
                            }
                            
                        } catch (error) {
                            console.error('Login failed:', error);
                        }
                        
                    }else{
                        //this.alertMessage("동일한 이메일로 가입된 사용자가 있습니다.");
                        this.alertMessage("동일한 이메일로 가입된 사용자가 있습니다.");
                    }
                }
            }
        },

        // 페이지 이동
        movePage(page){
            this.$router.push('/'+page);
        },

        alertMessage(msg){
            this.$swal({
                text: msg,
                padding: '3em',
                fontSize: '16px',
                color: '#fff',
                background: '#242424',
            });
        },
        
    },
}
</script>



