import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const branchStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        getGreetings: ({commit}, params) => { 
            
            var branch = "서울";
            branch = encodeURI(branch);

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Online/GetBranch/' + branch,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },


    }
}

export default branchStore