import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const contLogStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 시청 log 저장
        updateContProgress: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/ContLog/UpdateContProgress/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 탭 시청 기록 조회
        progressTabRecord: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Contents/ProgressTabRecord?useruid='+params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 영상 재생 로그에서 tabid 초기화
        resetProgressTabId: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Contents/ResetProgressTabId?useruid='+params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // tabId 매칭되는지 확인
        checkTabId: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Contents/CheckTabId/?useruid='+params.useruid+'&contentsid='+params.contentsid+'&videoid='+params.videoid, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default contLogStore