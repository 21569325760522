<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <!-- footer -->
        <footer id="footer">
            <div class="foot_wrap">
                <div class="foot_menu">
                    <ul>
                        <li><router-link to="/company">회사소개</router-link> </li>
                        <li><router-link to="/privacyPolicy">개인정보 처리방침</router-link> </li>
                        <li><router-link to="/termsOfUse">이용약관</router-link> </li>
                        <li><router-link to="/q&a">자주하는 질문</router-link> </li>
                        <li><router-link to="/notice">공지사항</router-link> </li>
                    </ul>
                </div>
                <div class="foot_infor">
                    <ul class="foot_sns">
                        <li class="ic1"><a target="_blank" href="https://www.instagram.com/magicbodypilates">인스타그램</a> </li>
                        <li class="ic3"><a target="_blank" href="https://www.youtube.com/channel/UCRGV8nPbhjGsSIsPl5ypImQ">유튜브</a> </li>
                        <li class="ic4"><a target="_blank" href="https://pf.kakao.com/_UPxnqxl">카카오톡</a> </li>
                        <li class="ic5"><a target="_blank" href="https://cafe.naver.com/magicbody">블로그</a> </li>
                    </ul>
                    <div class="infor_txt">
                        <span class="txt1">고객센터</span>
                        <span class="txt2">02.2672.2240</span>
                        <span class="txt3">오전 09시 - 오후 06시 (주말, 공휴일 제외)</span>
                    </div>
                </div>
                <address>
                    <span>국제재활필라테스협회(주)</span>
                    <span>대표자명 : 서재민</span>
                    <span>사업자번호 :  757-86-02622</span>
                    
                     <span>통신판매업 신고번호 : 2023-인천부평-1315</span>
                </address>
                <address>
                     <span>주소 : 인천광역시 부평구 부평대로 90, 에이동 702호(부평동,여산빌딩)</span>
                     <span>Tel : 02-2672-2240</span>
                </address>
                <small>COPYRIGHT @ 2024 MAGICBODY. ALL RIGHTS RESERVED.</small>
            </div>

            <!-- 모바일 하단 고정 메뉴 -->
            <div class="foot_fix_menu">
                <ul>
                    <li class="ic1 on"><router-link to="/">HOME</router-link> </li>
                    <li class="ic2"><router-link to="/myclass">내강의실</router-link> </li>
                    <li class="ic3"><router-link to="#" @click="toggleSearch">검색</router-link> </li>
                    <li class="ic4" :class="{ 'alim': cartCnt !== 0 }"><router-link to="/mycart">장바구니</router-link> </li>
                    <li class="ic5"><router-link to="javascript" @click="kakaoChannel">문의하기</router-link> </li>
                </ul>
            </div>
            <!-- //모바일 하단 고정 메뉴 -->

            <!-- 스크롤탑 버튼 -->
            <div class="foot_btn_top" :class="{ show: showScrollTop }"><button type="button" @click="scrollToTop"></button> </div>

            <!-- 카카오톡 채널 채팅 버튼 -->
            <div class="foot_btn_kaka"  id="kakao-talk-channel-chat-button"><button type="button" @click="kakaoChannel">카카오톡</button> </div>

        </footer>
        <!-- //footer -->

        <!-- 상단 / 모바일 검색 -->
        <MainSearch ref="mainSearch" @toggleEvent="toggleSearch"/>
        <!-- //상단 / 모바일 검색 -->

    </div>
</template>

<script setup>

</script>

<script>
import MainSearch from '../layout/mainSearch.vue';
export default {
    data() {
        return {
            showScrollTop: false,
            lastScrollTop: 0, // 마지막 스크롤 위치 저장
            useruid: "",
            cartCnt: 0,
        };
    },
    components: {
        MainSearch
    },
    mounted() {

        var user = this.$store.getters['user'];
        if(user.loggedIn){
            this.useruid = user.data.useruid;

            this.getMyClassInfo();
        }else{
            this.useruid = "";
        }

        window.addEventListener('scroll', this.handleScroll);
        this.emitter.on('watchCartChange', this.getMyClassInfo);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        this.emitter.off('watchCartChange', this.getMyClassInfo);
    },
    methods: {
        toggleSearch() {
            this.$refs.mainSearch.toggleSearch(); // MainSearch 컴포넌트의 toggleSearch() 메서드 호출
        },
        kakaoChannel() {
            /* if (window.Kakao) {
                window.Kakao.Channel.addChannel({
                channelPublicId: '_UPxnqxl' // 자신의 채널 아이디
                });
            } */
            // 카카오톡 채널 링크 새창으로 열기
            window.open('https://pf.kakao.com/_UPxnqxl');
           
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        handleScroll() {
            let currentScrollTop = window.scrollY;
            if (currentScrollTop < this.lastScrollTop) {
                // 스크롤 올릴 때
                this.showScrollTop = true;
            } else {
                // 스크롤 내릴 때
                this.showScrollTop = false;
            }
            this.lastScrollTop = currentScrollTop;
        },

        // 내 강의실 정보 가져오기
        getMyClassInfo(){

            var params = {
                useruid: this.useruid
            };
                    
            this.$store.dispatch('mypageStore/getMyClassInfo', params)
            .then((res) => {
                //console.log(res);
                if(res.success){

                    var myclassInfo = res.datas;
                    this.cartCnt = myclassInfo.cartCnt;
                    
                }
                return res;
            })
            .catch(({message}) => (alert(message)));
        },
    },
}
</script>




