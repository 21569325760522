import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const statsStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 메인 페이지 수치
        getStats: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Stats/GetInfo/' + 1,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
    }
}

export default statsStore