export default {
    //URL: 'https://localhost:7240',
    //URL: 'https://dev-mbapi.devaidsoft.net',
    URL: 'https://api.magicbodypilates.co.kr',
    ROUTES: {
        register: '/',
    },
    HEADERS: {
        'AppID' : '0b48a73c-5e31-45e7-83c6-b53c496a0388',
        'AppKey' : 'cb224bcf71e17f37b0c161fb1b92b1f3'
    },
    PGCODE: 'kcp.T0000',
}