import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const onlineStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 온라인 강의 리스트
        getOnlineLectures: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Contents/GetOnlineLectures/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 온라인 강의 상세
        getOnlineDetail: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Contents/GetOnlineDetail/"+params.contentsid+"/"+params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 태그 리스트
        getTags: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Tag/GetTags/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 커리큘럼 리스트
        getCurriculum: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                //axios.post(SERVER.URL + '/api/Contents/CurriculumList/'+params.contentsid+'/'+params.useruid, params,
                axios.post(SERVER.URL + '/api/Contents/CurriculumList/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 비슷한 강의 리스트
        getSimilarOnlineLectures: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Contents/GetSimilarOnlineLectures/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default onlineStore