<!-- mainSearch.vue -->
<template>
    <div class="top_search_box" :class="{ 'on': isMainSearchOpen }">
        <div class="logo"><img src="../../assets/img/logo.svg" alt="MAGICBODY"></div>
        <div class="search_con">
            <input ref="searchInput" type="text" class="ser_txt" v-model="schtext" placeholder="찾으시는 클래스가 있나요?" @keydown.enter="search">
            <input type="submit" value="검색" class="ser_btn" @click="search">
        </div>
        <button type="button" class="btn_search_close" @click="toggleSearch">닫기</button>
    </div>
</template>

<script>
import { mapActions } from 'vuex'; // Vuex의 action을 사용하기 위해 가져옴

export default {
    data() {
        return {
            schtext: '',
            isMainSearchOpen: false, 
        };
    },
    mounted() {
        this.$router.afterEach(() => {
            this.isMainSearchOpen = false; // 페이지 이동 후에 검색 창을 닫음
        });
    },
    methods: {
        toggleSearch() { // 검색

            this.isMainSearchOpen = !this.isMainSearchOpen;
            this.schtext = ''; // 입력값 초기화

            if (this.isMainSearchOpen) {
                this.$nextTick(() => {
                    this.$refs.searchInput.focus(); // input 요소에 포커스 설정
                });
            }    

        },
        search() { // 검색 실행

            if (this.schtext == '') {
                //alert('검색어를 입력해주세요.')
                this.alertMessage('검색어를 입력해주세요.');
                this.$nextTick(() => {
                    this.$refs.searchInput.focus(); // input 요소에 포커스 설정
                });
            } else {
                this.$router.push({ name: 'search', query: { schtext: this.schtext } }); // 검색 결과 페이지로 이동
                this.isMainSearchOpen = false;
            }
            
        },
        ...mapActions('router', ['push']), // Vue Router의 push 액션을 가져와서 사용

        alertMessage(msg){
            this.$swal({
                text: msg,
                padding: '3em',
                fontSize: '16px',
                color: '#fff',
                background: '#242424',
            });
        },
    },
}
</script>


