<template>
    <div :class="{ 'typeA': !isSpecialPage, 'typeB': isSpecialPage }">
      <div class="body">
        <Header :class="bodyClass"></Header>
        <router-view></router-view>
        <Footer></Footer>
      </div>
    </div>
</template>

<script>

import Header from '@/components/layout/header.vue'; // 헤더
import Footer from '@/components/layout/footer.vue'; // 푸터

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      bodyClass: '', // Initial empty body class
    };
  },
   created() {
    // Update body class initially when component is created
    this.updateBodyClass(this.$route.meta.bodyClass);
  },
  computed: {
    // 현재 페이지가 특정 페이지인지 여부를 계산하여 반환합니다.
    isSpecialPage() {
      //console.log(this.$route.path);
      return this.$route.path === '/mycart' || 
      this.$route.path === '/myclass' || 
      this.$route.path === '/myclassEnd' || 
      this.$route.path === '/myEbook' || 
      this.$route.path === '/myBookmark' || 
      this.$route.path === '/myCertificates' || 
      this.$route.path === '/mypayment' || 
      this.$route.path === '/mypoint' || 
      this.$route.path === '/myprofile' || 
      this.$route.path === '/company' || 
      this.$route.path === '/privacyPolicy' || 
      this.$route.path === '/termsOfUse' || 
      this.$route.path === '/q&a' || 
      this.$route.path === '/notice' || 
      this.$route.matched.some(record => record.path.startsWith('/payment/'))  || 
      this.$route.matched.some(record => record.path.startsWith('/classView/')) ;
    },
  },
  watch: {
    // Watch for route changes and update body class accordingly
    '$route'(to, from) {
      this.updateBodyClass(to.meta.bodyClass);
    },
  },
  methods: {
    updateBodyClass(bodyClass) {
      if (bodyClass) {
        this.bodyClass = bodyClass;
      } else {
        this.bodyClass = '';
      }
    },
  },
}
</script>
