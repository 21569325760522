import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const commonStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 파일 업로드
        uploadFiles: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/UploadFiles/', params,
                { headers: SERVER.HEADERS, 'Content-Type': 'multipart/form-data', data: params }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 파일 url 가져오기
        getFileUrl: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/file/url?filename='+params.filename,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })

        },

    }
}

export default commonStore