import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import mitt from "mitt"
//
import AnimateOnScroll from 'primevue/animateonscroll';
import Dialog from 'primevue/dialog';
import VueSweetalert2 from 'vue-sweetalert2';
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'



//https://primevue.org/
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/lara-light-teal/theme.css'; // 테마 CSS 파일
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css'

// 로컬 css
import './assets/css/base.css';
import './assets/css/content.css';
import './assets/css/layout.css';
import './assets/css/primecustom.css';





// sweetalert2
// https://sweetalert2.github.io/
import 'sweetalert2/dist/sweetalert2.min.css';
// 알랏 공통 옵션
const options = {
    confirmButtonColor: '#EE732E',
    cancelButtonColor: '#FFF',
    confirmButtonText: '확인',
    cancelButtonText: '취소',
    padding: '3em',
    color: '#fff',
    background: '#242424',
};


const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter

app.component('Dialog', Dialog);
app.use(router).use(store).use(PrimeVue).use(VueSweetalert2, options).directive('animateonscroll', AnimateOnScroll).use(VuePlyr, {
    plyr: {}
}).mount('#app');

// 카카오 연동 로그인
window.Kakao.init("0b7caf7054e6863bfb79b49b81ec44f0");