import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const ratingStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 수강평 리스트
        getRatings: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Rating/GetRatings/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
        // 수강평 리스트 및 별점 정보
        getRatingStarInfo: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Rating/GetRatingStarInfo/' + params.contentsid + '?sortType='+params.sortType, {},
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 수강평 등록
        saveRatings: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Rating/Save/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 수강평 삭제
        deleteRating: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Rating/Delete?id='+params.ratingid+'&contentsid='+params.contentsid+'&ratescore='+params.ratescore, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
    }
}

export default ratingStore