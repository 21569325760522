export default {
    data() {
        return {
            lastScrollTop: 0
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.setupGnbEvents();
    },
    methods: {
        handleScroll() {
            const scrollTop = window.scrollY;

            if (scrollTop >= 0) {
                if (scrollTop > this.lastScrollTop && this.lastScrollTop > 0) {
                    this.isHeaderFixed = false;
                    this.isHeaderFixed2 = true;
                } else {
                    this.isHeaderFixed = true;
                    this.isHeaderFixed2 = false;
                }
                this.lastScrollTop = scrollTop;
            }

            if (scrollTop < 70) {
                this.isHeaderFixed = false;
            }

           
        },
        setupGnbEvents() { // 메인 헤더 hover
            const gnb = document.getElementById('gnb');
            const gnbItems = gnb.querySelectorAll('.gnb > li');

            gnbItems.forEach(item => {
                item.addEventListener('mouseenter', this.handleMouseEnter);
                item.addEventListener('focusin', this.handleFocusIn);
                item.addEventListener('mouseleave', this.handleMouseLeave);
                item.addEventListener('focusout', this.handleFocusOut);
            });

            const subMenus = gnb.querySelectorAll('.gnb');

            subMenus.forEach(subMenu => {
                subMenu.addEventListener('mouseenter', this.handleSubMenuEnter);
                subMenu.addEventListener('focusin', this.handleSubMenuEnter);
                subMenu.addEventListener('mouseleave', this.handleSubMenuLeave);
            });
        },
        handleMouseEnter(event) {
            event.target.classList.add('hv');
        },
        handleFocusIn(event) {
            event.target.classList.add('hv');
        },
        handleMouseLeave(event) {
            event.target.classList.remove('hv');
        },
        handleFocusOut(event) {
            event.target.classList.remove('hv');
        },
        handleSubMenuEnter(event) {
            event.target.classList.add('on');
        },
        handleSubMenuLeave(event) {
            event.target.classList.remove('on');
        },

        


    }
};