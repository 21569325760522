import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const quizStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 퀴즈 문제 리스트
        getQuizList: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Evaluate/GetTest/'+params.contentsid+'/'+params.useruid+'/'+params.isscoreid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
        // 퀴즈 채점
        scoreQuiz: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Evaluate/Scoring/0', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 퀴즈 채점 내역
        scoreQuizResults: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Evaluate/Scoring/1', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 재응시 가능여부 체크
        getRetakeQuiz: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Evaluate/IsRetakeQuiz/'+params.quizid+'/'+params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 퀴즈 합격했는지 확인
        existsPassScoreid: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Evaluate/ExistsPassScoreid/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default quizStore