import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const boardStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 메인 공지사항 리스트
        getMainNotices: ({commit}, params) => { 

            var isonlybranch = true;
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Main/GetNotices?isnotice=1&istop3=false&isonlybranch=' + isonlybranch, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 공지사항 리스트
        getNotices: ({commit}, params) => { 

            var isonlybranch = true;
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Main/GetNotices?isnotice=-1&istop3=false&isonlybranch=' + isonlybranch, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 공지사항 정보
        getNoticeInfo: ({commit}, noticeid) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Main/GetNoticeInfo/'+noticeid+'/1',
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // FAQ 리스트
        getFaq: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Board/GetFaqs/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 건의사항 리스트
        getCodeList: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/GetCode/suggesttype',
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 건의사항 리스트
        getSuggestions: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Board/GetSuggests/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 건의사항 등록/수정
        saveSuggest: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Board/SaveSuggest/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 건의사항 정보
        getSuggestInfo: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Board/GetSuggestInfo/' + params.suggestid + '/1/' + params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 건의사항 삭제
        deleteSuggest: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Board/DeleteSuggest?suggestid=' + params.suggestid, {},
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default boardStore