// src/composables/useAuth.js
import { ref } from 'vue';
import { supabase } from '../lib/supabaseClient';
import { v4 as uuidv4 } from 'uuid';

const user = ref(null);
const sessionToken = ref(null);

// supabase users 테이블에 사용자 로그인 정보 저장
const authLogin = async (useruid, email) => {

    sessionToken.value = uuidv4(); // 고유 세션 토큰 생성

    //console.log(sessionToken);

    var last_login = new Date();
    last_login.setHours(last_login.getHours() + 9);
    last_login = new Date(last_login);

    const { data, error } = await supabase.from('users')
        .upsert({
            id: useruid,
            last_login: last_login,
            active_session_token: sessionToken.value,
        }, { onConflict: ['id'] });

    if (error) {
        console.log(error);
        return {
            success: false,
            error: "동일한 이메일로 가입된 사용자가 있습니다.",
        };
        throw error;
    }

    user.value = {
        id: useruid,
        email: email,
        sessionToken: sessionToken.value,
        success: true,
    };

    //console.log(user.value);
    return user.value;
};

// supabase users 테이블에 사용자 로그인 정보 null 처리
const authLogout = async () => {
    if (user.value) {
        await supabase.from('users')
            .update({ active_session_token: null })
            .eq('id', user.value.id);

        user.value = null;
        sessionToken.value = null;
    }
};

export { user, sessionToken, authLogin, authLogout };
