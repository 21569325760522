import { createRouter, createWebHistory } from 'vue-router';
import Swal from 'sweetalert2'; // Import Swal
import EmptyLayout from '../components/layout/EmptyLayout.vue';
import DefaultLayout from '../components/layout/DefaultLayout.vue';

import store from '../store';
import VueCookies from 'vue-cookies';
import VueJwtDecode from 'vue-jwt-decode';
import { supabase } from '../lib/supabaseClient'

const routes = [
    
    // home
    {
        path: '/',
        redirect: '/main',
        meta: {
            title: "MAGICBODY",
            layout: DefaultLayout
        },
    }, 

    // 메인
    {
        path: '/main',
        name: 'main',
        component: () => import('../views/home/main.vue'),
        meta: {
            title: "MAGICBODY",
            layout: DefaultLayout
        },

    },

    // 메인테스트
    {
        path: '/main-test',
        name: 'main-test',
        component: () => import('../views/home/main-original.vue'),
        meta: {
            title: "MAGICBODY",
            layout: DefaultLayout
        },

    },

    // 로그인
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login.vue'),
        meta: {
            title: "로그인",
            layout: DefaultLayout
        },
    },

    // 회원가입
    {
        path: '/join',
        name: 'join',
        component: () => import('../views/account/join.vue'),
        meta: {
            title: "회원가입",
            layout: DefaultLayout
        },
    },

    // 통합검색
    {
        path: '/search',
        name: 'search',
        component: () => import('../views/home/search.vue'),
        meta: {
            title: "통합검색",
            layout: DefaultLayout
        },
    },

    // 클래스 리스트 (온라인,오프라인,패키지,전자책)
    {
        path: '/classList/:categoryid/:pcategoryid',
        name: 'classList',
        component: () => import('../views/class/classList.vue'),
        meta: {
            title: "클래스 리스트",
            layout: DefaultLayout
        },
    },


     // 클래스 상세
    {
        path: '/classView/:contentsid',
        name: 'classView',
        component: () => import('../views/class/classView.vue'),
        meta: {
            title: "클래스 상세",
            layout: DefaultLayout,
             bodyClass: 'subpage' 
        },
    },

    // 결제하기
    {
        path: '/payment/:contentsids',
        name: 'payment',
        component: () => import('../views/class/payment.vue'),
        meta: {
            title: "결제하기",
            layout: DefaultLayout,
            authRequired: true
        },
    },

     // 내강의실 > 수강중인클래스
    {
        path: '/myclass',
        name: 'myclass',
        component: () => import('../views/mypage/myclass.vue'),
        meta: {
            title: "내강의실 - 수강중인 클래스",
            layout: DefaultLayout,
            authRequired: true
        },
    },
    // 내강의실 > 학습진행상태
    {
        path: '/myclassProgress/:contentsid',
        name: 'myclassProgress',
        component: () => import('../views/mypage/myclassProgress.vue'),
        meta: {
            title: "내강의실 - 학습진행상태",
            layout: DefaultLayout,
            authRequired: true
        },
    },

    // 내강의실 > 종료 된 클래스
    {
        path: '/myclassEnd',
        name: 'myclassEnd',
        component: () => import('../views/mypage/myclassEnd.vue'),
        meta: {
            title: "내강의실 - 종료 된 클래스",
            layout: DefaultLayout,
            authRequired: true
        },
    },

    // 내강의실 > 전자책 관리
    {
        path: '/myEbook',
        name: 'myEbook',
        component: () => import('../views/mypage/myEbook.vue'),
        meta: {
            title: "내강의실 - 전자책관리",
            layout: DefaultLayout,
            authRequired: true
        },
    },

     // 내강의실 > 북마크
    {
        path: '/myBookmark',
        name: 'myBookmark',
        component: () => import('../views/mypage/myBookmark.vue'),
        meta: {
            title: "내강의실 - 북마크",
            layout: DefaultLayout,
            authRequired: true
        },
    },

    // 내강의실 > 수료증발급
    {
        path: '/myCertificates',
        name: 'myCertificates',
        component: () => import('../views/mypage/myCertificates.vue'),
        meta: {
            title: "내강의실 - 수료증발급",
            layout: DefaultLayout,
            authRequired: true
        },
    },

    // 내강의실 > 수료증발급 프린트
    {
        path: '/myCertificatesPrint/:contentsid',
        name: 'myCertificatesPrint',
        component: () => import('../views/mypage/myCertificatesPrint.vue'),
        meta: {
            title: "내강의실 - 수료증발급 프린트",
            layout: EmptyLayout,
            authRequired: true
        },

    },

    // 내강의실 > 결제내역조회
    {
        path: '/mypayment',
        name: 'mypayment',
        component: () => import('../views/mypage/mypayment.vue'),
        meta: {
            title: "내강의실 - 결제내역조회",
            layout: DefaultLayout,
            authRequired: true
        },
    },

    // 내강의실 > 포인트 사용내역
    {
        path: '/mypoint',
        name: 'mypoint',
        component: () => import('../views/mypage/mypoint.vue'),
        meta: {
            title: "내강의실 - 포인트 사용내역",
            layout: DefaultLayout,
            authRequired: true
        },
    },

    // 내강의실 > 회원정보 수정
    {
        path: '/myprofile',
        name: 'myprofile',
        component: () => import('../views/mypage/myprofile.vue'),
        meta: {
            title: "내강의실 - 회원정보 수정",
            layout: DefaultLayout,
            authRequired: true
        },
    },

    // 장바구니
    {
        path: '/mycart',
        name: 'mycart',
        component: () => import('../views/mypage/mycart.vue'),
        meta: {
            title: "장바구니",
            layout: DefaultLayout,
            authRequired: true
        },
    },

    // 회사소개
    {
        path: '/company',
        name: 'company',
        component: () => import('../views/footer/company.vue'),
        meta: {
            title: "회사소개",
            layout: DefaultLayout
        },
    },

    // 자주하는 질문
    {
        path: '/q&a',
        name: 'q&a',
        component: () => import('../views/footer/q&a.vue'),
        meta: {
            title: "자주하는 질문",
            layout: DefaultLayout
        },
    },

    // 공지사항
    {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/footer/notice.vue'),
        meta: {
            title: "공지사항",
            layout: DefaultLayout
        },
    },
     // 공지사항 상세보기
    {
        path: '/noticeView/:noticeid',
        name: 'noticeView',
        component: () => import('../views/footer/noticeView.vue'),
        meta: {
            title: "공지사항 상세보기",
            layout: DefaultLayout
        },
    },

    // 플레이어
    {
        path: '/player/:contentsid',
        name: 'player',
        component: () => import('../views/play/player.vue'),
        meta: {
            title: "클래스 플레이어",
            layout: EmptyLayout,
            authRequired: true
        },
    },

    // 결제 성공
    {
        path: '/paymentSuccess',
        name: 'paymentSuccess',
        component: () => import('../views/mypage/paymentSuccess.vue'),
        meta: {
            title: "결제 성공",
            layout: EmptyLayout
        },
    },

    // 결제 실패
    {
        path: '/paymentFail',
        name: 'paymentFail',
        component: () => import('../views/mypage/paymentFail.vue'),
        meta: {
            title: "결제 실패",
            layout: EmptyLayout
        },
    },
    
    // 결제 완료
    {
        path: '/paymentResult',
        name: 'paymentResult',
        component: () => import('../views/mypage/paymentResult.vue'),
        meta: {
            title: "결제 완료",
            layout: EmptyLayout
        },
    },

    // 결제 완료
    {
        path: '/paymentResultFree',
        name: 'paymentResultFree',
        component: () => import('../views/mypage/paymentResultFree.vue'),
        meta: {
            title: "결제 완료",
            layout: EmptyLayout
        },
    },

    // 개인정보처리방침
    {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        component: () => import('../views/footer/privacyPolicy.vue'),
        meta: {
            title: "개인정보처리방침",
            layout: DefaultLayout
        },
    },

      // 이용약관
    {
        path: '/termsOfUse',
        name: 'termsOfUse',
        component: () => import('../views/footer/termsOfUse.vue'),
        meta: {
            title: "termsOfUse",
            layout: DefaultLayout
        },
    },

    

    
];

const router = createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkActiveClass: "active",
    //linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

// supabase 세션 토큰 확인
async function checkSessionToken(useruid, sessionToken) {
    const { data, error } = await supabase.from('users')
        .select('active_session_token')
        .eq('id', useruid);

    if (error) {
        console.error("Error fetching session token:", error);
        return false;
    }

    /*
    console.log("supabase 세션 토큰 확인");
    console.log(data);
    console.log(sessionToken);
    */
    if (data.length > 0 && data[0].active_session_token !== sessionToken) {
        //alert("다른 기기에서 로그인되었습니다.");
        Swal.fire({
            text: "다른 기기에서 로그인되었습니다.",
            confirmButtonColor: '#EE732E',
            cancelButtonColor: '#FFF',
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            padding: '3em',
            color: '#fff',
            background: '#242424',
        });
        
        store.commit('logoutUser');
        const { signOutError } = await supabase.auth.signOut();
        if (signOutError) {
            console.error("Error during sign out:", signOutError);
        }
        return false;
    }
    return true;
}

function isInAppBrowser() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    console.log(ua);
  
    // 인스타그램 앱의 user-agent에 "Instagram"이 포함됨
    if (ua.indexOf('Instagram') > -1) {
        return 'instagram';
    }else if (ua.indexOf('KAKAOTALK') > -1) {
        return 'kakaotalk';
    }else{
        return 'other';
    }
}

function getPlatform() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(ua)) {
        return 'android';
    } else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
        return 'ios';
    } else {
        return 'other';
    }
}

router.beforeEach(async (to, from, next) => {

    /*
     * to: 이동할 url 정보가 담긴 라우터 객체
     * from: 현재 url 정보가 담긴 라우터 객체
     * next: to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
     * next() 가 호출되기 전까지 화면 전환되지 않음
    */

    /*
    var browserType = isInAppBrowser();
    var platform = getPlatform();
    console.log(browserType);
    console.log(platform);

    if (browserType != 'other') {
        const targetUrl = window.location.href;
        if (platform === 'android') {
            window.location.href = `intent://${targetUrl.replace(/^https?:\/\//, '')}#Intent;scheme=https;package=com.android.chrome;end;`;
        } else if (platform === 'ios') {
            if(browserType == 'instagram'){
                window.location.href = `intent://${targetUrl.replace(/^https?:\/\//, '')}#Intent;scheme=https;package=com.android.chrome;end;`;
            }else{
                window.location.href = `kakaotalk://web/openExternal?url=${encodeURIComponent(targetUrl)}`;
            }
        }
    }
    */
    
    // 특정페이지에만 헤더에 class 추가
    const bodyClass = to.meta.bodyClass;
    if (bodyClass) {
        document.body.className = bodyClass;
    } else {
        document.body.className = ''; 
    }
   
    var token = VueCookies.get('magicbody_accessToken');
    var magicbody_sessionToken = VueCookies.get('magicbody_sessionToken');
    //console.log(magicbody_sessionToken);
    var isVerified = false;


    if(token !== null){

        if(token.includes('.')){
            let decoded = VueJwtDecode.decode(token)
            var expTime = decoded.exp;
            var expireDate = new Date(expTime * 1000);
            var today = new Date();

            if(expireDate > today){
                isVerified = true;
            }
        }
        
        // test fix
        if(token == "111"){
            isVerified = true;
        }
    }

    if (store.state.user.loggedIn) {
        document.title = to.meta.title || 'MAGICBODY';


        next();
        /*
        // 중복로그인 체크
        const isValidSession = await checkSessionToken(store.state.user.data.useruid, store.state.user.data.sessionToken);
        if (!isValidSession) {
            next("/login");
        } else {
            next();
        }
        */
       
    }else{

        if (token !== null){
            //console.log("accessToken이 있을 경우 진행");
            //accessToken이 있을 경우 진행
            var userInfo = {
                "accessToken": VueCookies.get('magicbody_accessToken'),
                "refreshToken": VueCookies.get('magicbody_refreshToken'),
                "email": VueCookies.get('magicbody_email'),
                "useruid": VueCookies.get('magicbody_useruid'),
                "username": VueCookies.get('magicbody_username'),
                "sessionToken": VueCookies.get('magicbody_sessionToken'),
            };

            //console.log(userInfo)
    
            if(isVerified){
                store.commit('setLoggedIn', true);
                store.commit('resetUserInfo', userInfo);

                next();

                /*
                // 중복로그인 체크
                const isValidSession = await checkSessionToken(store.state.user.data.useruid, store.state.user.data.sessionToken);
                if (!isValidSession) {
                    next("/login");
                } else {
                    next();
                }
                */

            }else{

                store.commit('logoutUser');
                const { error } = supabase.auth.signOut();
                //alert('로그인 후 이용해주세요.');
                // sweetalert2 사용하여 alert 대체
                Swal.fire({
                    text: "로그인 후 이용해주세요.",
                    confirmButtonColor: '#EE732E',
                    cancelButtonColor: '#FFF',
                    confirmButtonText: '확인',
                    cancelButtonText: '취소',
                    padding: '3em',
                    color: '#fff',
                    background: '#242424',
                });

                next("/login");

            }
            
        }else{

            if(!isVerified && token !== null){
                // accessToken이 종료되었을 경우 로그아웃 진행
                store.commit('logoutUser');
                const { error } = supabase.auth.signOut()
            }
            const authRequired = to.matched.some((route) => route.meta.authRequired)

            if (!authRequired) {
                document.title = to.meta.title || 'MAGICBODY';

                next();

            }else{
                //alert('로그인 후 이용해주세요.');
                Swal.fire({
                    text: "로그인 후 이용해주세요.",
                    confirmButtonColor: '#EE732E',
                    cancelButtonColor: '#FFF',
                    confirmButtonText: '확인',
                    cancelButtonText: '취소',
                    padding: '3em',
                    color: '#fff',
                    background: '#242424',
                });
                next("/login");
            }
        }
    }




});

export default router;