import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const mainStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 메인 배너 가져오기
        getMainBanner: ({commit}, params) => { 
            
            var isonlybranch = true;

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Banner/GetList?isonlybranch=' + isonlybranch, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 메인 커뮤니티 리스트 가져오기
        getNoticeList: ({commit}, params) => { 
            
            var isonlybranch = true;

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Main/GetNotices?isnotice=1&istop3=false&isonlybranch=' + isonlybranch, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 최신 강의 리스트
        getRecentOnlineLectures: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Contents/GetRecentOnlineLectures/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 진열 강의 리스트
        getMainContents: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Type/GetMainContents/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 인기 강의 리스트
        getPopularOnlineLectures: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Contents/GetPopularOnlineLectures/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 메인 리뷰 리스트
        getMainReviews: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Review/GetReviews/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default mainStore