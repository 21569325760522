import axios from "axios";
import SERVER from '@/API/url'
import VueCookies from 'vue-cookies';

/* eslint-disable */
var lnbMenu = [];

/* eslint-disable */
new Promise((resolve, reject) => {
    axios.get(SERVER.URL + '/api/Category/GetList/category?isactive=1&pcategoryid=0',
    { headers: SERVER.HEADERS }
    ).then(res => {
        
        //console.log(res.data);
        //console.log(res.data.datas);

        var resData = res.data;
        var param = document.location.href;
        var paramArr = param.split("/");
        var categoryid = paramArr[paramArr.length-2];
        var pcategoryid = paramArr[paramArr.length-1];
        pcategoryid = pcategoryid.replace("#", "");

        var firstDepth = 0;

        if(resData.datas.length > 0){
            if(categoryid != 0){
                for (i = 0; i < resData.datas.length; i++) {
                    if(resData.datas[i].id == categoryid){
                        firstDepth = resData.datas[i].parentID;
                    }
                }
            }else{
                firstDepth = 0;
            }

            /*
            var menuData = {
                categoryid: 0,
                pcategoryid: 0,
                menuName: "전체",
                hasChildren: false,
            };

            lnbMenu.push(menuData);

            if(categoryid == 0 && pcategoryid == 0){
                lnbMenu[0].isActive = "active";
            }else{
                lnbMenu[0].isActive = "";
            }
            
            */

            var lnbCnt = 0;
            
            for(var i = 0; i < resData.datas.length; i++){

                if (resData.datas[i].parentID == 0) {

                    var menuData = {
                        categoryid: resData.datas[i].categoryid,
                        pcategoryid: resData.datas[i].pcategoryid,
                        menuName: resData.datas[i].ctgrname,
                        hasChildren: resData.datas[i].hasChildren,
                    };

                    lnbMenu.push(menuData);

                    if(resData.datas[i].id == categoryid){
                        lnbMenu[lnbCnt].isOn = "on";
                        lnbMenu[lnbCnt].isActive = "active";
                    }else if(firstDepth != 0){
                        if(resData.datas[i].id == firstDepth){
                            lnbMenu[lnbCnt].isOn = "on";
                            lnbMenu[lnbCnt].isActive = "active";
                        }else{
                            lnbMenu[lnbCnt].isOn = "";
                            lnbMenu[lnbCnt].isActive = "";
                        }
                    }else if(firstDepth == 0 && pcategoryid == resData.datas[i].id){
                        lnbMenu[lnbCnt].isOn = "on";
                        lnbMenu[lnbCnt].isActive = "active";
                    }else{
                        lnbMenu[lnbCnt].isOn = "";
                        lnbMenu[lnbCnt].isActive = "";
                    }

                    if (resData.datas[i].hasChildren) {
                        lnbMenu[lnbCnt].subMenu = [];

                        var subCnt = 0;

                        // depth 3
                        for (var j = 0; j < resData.datas.length; j++) {
                            if (resData.datas[i].id == resData.datas[j].parentID) {

                                var menuData = {
                                    categoryid: resData.datas[j].categoryid,
                                    pcategoryid: resData.datas[j].pcategoryid,
                                    menuName: resData.datas[j].ctgrname,
                                    hasChildren: resData.datas[j].hasChildren,
                                };
                                
                                lnbMenu[lnbCnt].subMenu.push(menuData);            
    
                                if(resData.datas[j].id == categoryid){
                                    lnbMenu[lnbCnt].subMenu[subCnt].isOn = "on";
                                    lnbMenu[lnbCnt].subMenu[subCnt].isActive = "active";
                                }else if(resData.datas[j].id == pcategoryid){
                                    lnbMenu[lnbCnt].subMenu[subCnt].isOn = "on";
                                    lnbMenu[lnbCnt].subMenu[subCnt].isActive = "active";
                                }else{
                                    lnbMenu[lnbCnt].subMenu[subCnt].isOn = "";
                                    lnbMenu[lnbCnt].subMenu[subCnt].isActive = "";
                                }
    
                                if (resData.datas[j].hasChildren) {
                                    lnbMenu[lnbCnt].subMenu[subCnt].subsubMenu = [];

                                    var subsubCnt = 0;

                                    // depth 4
                                    for (var k = 0; k < resData.datas.length; k++) {
                                        if (resData.datas[j].id == resData.datas[k].parentID) {
                                            
                                            var menuData = {
                                                categoryid: resData.datas[k].categoryid,
                                                pcategoryid: resData.datas[k].pcategoryid,
                                                menuName: resData.datas[k].ctgrname,
                                                hasChildren: false,
                                            };
                                            
                                            lnbMenu[lnbCnt].subMenu[subCnt].subsubMenu.push(menuData);

                                            if(resData.datas[k].id == pcategoryid){
                                                lnbMenu[lnbCnt].subMenu[subCnt].subsubMenu[subsubCnt].isActive = "active";
                                            }else{
                                                lnbMenu[lnbCnt].subMenu[subCnt].subsubMenu[subsubCnt].isActive = "";
                                            }
        
                                            subsubCnt++;
                                        }
                                    }
                                }
    
                                subCnt++;
    
                            }
                        }
                    }

                    lnbCnt++;

                }
            }
        }
        //return lnbMenu;
    })
    .catch(err => {
        console.log(err.message);
        reject(err.message);
    });
});

export const menuItems = lnbMenu;