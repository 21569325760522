import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const mypageStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 나의 강의 리스트
        getMyLectures: ({commit}, params) => { 
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Contents/GetMyLectures/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 강의 상세 정보
        getOnlineDetail: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Contents/GetOnlineDetail/'+params.contentsid+'/'+params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 커리큘럼 섹션 리스트
        getCurriculumSection: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Contents/GetCurriculumSection/'+params.contentsid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 커리큘럼 섹션 영상 리스트
        getSectionVideo: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Video/GetSectionVideo/'+params.sectionid+'/'+params.useruid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 학습자료 리스트
        getContentsPdfList: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Contents/GetContentsPdfList/'+params.contentsid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 강의 영상 정보
        getVideoInfo: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Contents/GetVideo/'+params.contentsid+'/'+params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 강의 영상 URL
        getVideoSourceUrl: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Contents/GetVideoSourceUrl/'+params.id,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 강의 Q&A 리스트
        getQnaList: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/QnA/GetList/'+params.contentsid+'/'+params.useruid+''+params.canIOnlySee, {},
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // Q&A 질문 등록
        saveQna: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/QnA/Save/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 수강완료
        getCompleteList: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Online/GetCompleteList/'+params.onlineid+'/'+params.useruid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 장바구니 리스트 / 관심교육 리스트
        getOnlineLectures: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Contents/GetOnlineLectures/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 장바구니 추가
        addCartItem: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Cart/"+params.type+"/"+params.itemtype+"/"+params.useruid+"/"+params.contentsid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 장바구니 추가
        addCartItemWithOption: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Cart/"+params.type+"/"+params.itemtype+"/"+params.useruid+"/"+params.contentsid+"/"+params.optionvalue,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 장바구니 삭제
        manageCartItem: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Cart/"+params.type+"/"+params.itemtype+"/"+params.useruid+"/"+params.contentsid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 이수 강의가 있는지 체크
        isCompleteTitle: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Payment/IsCompleteTitle/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 강의 정보 체크 - 결제 가능여부 체크
        savePayment: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Payment/Save/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 강의 결제 완료
        updatePaid: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Payment/UpdatePaid/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 결제 리스트
        getOrderLectures: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Contents/GetOrderLectures/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 결제내역 리스트
        getPaymentList: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + "/api/Payment/GetPaymentList/", params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 결제내역 상세
        getPaymentInfo: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Payment/GetInfo/" + params.paymentid + "/" + params.contentsid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 첫시청 업데이트
        updateFirstPlay: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + "/api/Payment/FirstPlay/" + params.useruid + "/" + params.contentsid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 자료 다운로드 체크
        updateDownload: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + "/api/Payment/UpdateDownload/" + params.useruid + "/" + params.contentsid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 파일 url 가져오기
        getFileUrl: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/file/url?filename=" + params.filename,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 자료 다운로드
        downloadFile: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/file/downloadstream?filename=" + params.filename + "&originalFilename="+params.originalFilename,
                { headers: SERVER.HEADERS, responseType: 'blob' }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 진행율 업데이트
        updateWatchTime: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + "/api/Video/UpdateWatchTime/", params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 마지막 시청시각 가져오기
        getLastWatchTime: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Contents/GetLastWatchTime/"+params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 수강평 - 강사 강의 리스트
        getPublisherLectures: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + "/api/Contents/GetPublisherLectures/", params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 내 강의실 정보
        getMyClassInfo: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Contents/GetMyClassInfo/" + params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 내 포인트 리스트
        getPointList: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + "/api/Point/GetList/", params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 내 보유 포인트
        getPointSum: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Point/GetPointSum/" + params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 쿠폰 확인
        getCouponInfo: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/Coupon/GetCouponInfo/" + params.couponnumber,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 포인트 사용
        usePoint: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + "/api/Point/Save/", params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 쿠폰 사용
        updateCoupon: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + "/api/Coupon/Update/", params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default mypageStore